import { Context, Controller } from '@hotwired/stimulus';
import { OneTimeDialog } from 'src/dialog';
import * as Turbolinks from 'turbolinks';
import { v4 as uuid } from 'uuid';

const nullCallback = function () {};
const nullDelegate = {
  viewInvalidated: nullCallback,
  viewWillRender: nullCallback,
  viewRendered: nullCallback,
};

export default class FormController extends Controller {
  public static values = {
    'source-url': String,
  };

  private declare hasSourceUrlValue: boolean;
  private declare sourceUrlValue: string;

  constructor(props: Context) {
    super(props);

    this.onFormError = this.onFormError.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormRemoteSend = this.onFormRemoteSend.bind(this);
    this.onFormRemoteComplete = this.onFormRemoteComplete.bind(this);

    this.freezeForm = this.freezeForm.bind(this);
    this.restoreForm = this.restoreForm.bind(this);
  }

  connect(): void {
    this.element.addEventListener('click', this.onSubmitClick);
    this.element.addEventListener('submit', this.onFormSubmit);
    this.element.addEventListener('ajax:send', this.onFormRemoteSend);
    this.element.addEventListener('ajax:complete', this.onFormRemoteComplete);

    this.element.addEventListener('ajax:success', this.onFormSuccess);
    this.element.addEventListener('ajax:error', this.onFormError);
    this.element.addEventListener('turbo:submit-end', this.onFormError);
  }

  disconnect(): void {
    this.element.removeEventListener('click', this.onSubmitClick);
    this.element.removeEventListener('submit', this.onFormSubmit);
    this.element.removeEventListener('ajax:send', this.onFormRemoteSend);
    this.element.removeEventListener(
      'ajax:complete',
      this.onFormRemoteComplete,
    );

    this.element.removeEventListener('ajax:success', this.onFormSuccess);
    this.element.removeEventListener('ajax:error', this.onFormError);
    this.element.removeEventListener('turbo:submit-end', this.onFormError);
  }

  private onSubmitClick(event: Event) {
    const clickedElement = event.target;
    if (!(clickedElement instanceof HTMLElement)) {
      return;
    }

    const clickedSubmit = clickedElement.closest(
      'button:not([type="button"]), input[type="submit"]',
    );
    if (!clickedSubmit) {
      return;
    }

    this.element
      .querySelectorAll('[data-form-target="submitter"]')
      .forEach((oldSubmitter) =>
        oldSubmitter.removeAttribute('data-form-target'),
      );

    clickedSubmit.setAttribute('data-form-target', 'submitter');

    const replacementContent = clickedSubmit.getAttribute(
      'data-form-submit-value',
    );

    if (replacementContent) {
      clickedSubmit.setAttribute(
        'data-form-restore-value',
        clickedSubmit.textContent || '',
      );
      clickedSubmit.textContent = replacementContent;
    }
  }

  private onFormSubmit() {
    setTimeout(this.freezeForm, 13);
  }

  private onFormRemoteSend() {
    this.freezeForm();
  }

  private onFormRemoteComplete() {
    this.restoreForm();
  }

  private onFormError(event: Event) {
    if (!('detail' in event) || event.detail === null) {
      return;
    }

    // TODO: Do not support submit-end yet
    if (!Array.isArray(event.detail)) {
      return;
    }

    const message = this.extractMessage(event.detail[0] || 'no additional details provided');
    const title = event.detail[1];
    const status = event.detail[2].status;

    if (status >= 200 && status < 400) {
      return;
    }

    console.error(`${title} (${status})`);
    console.error(message);

    const template = document.getElementById('form-modal-template');

    if (!('content' in document.createElement('template')) || !template) {
      alert(`${title} (${status}): please report this to your Opus consultant`);
      return;
    }

    const id = uuid();

    const description = this.descriptionFor(status, message);
    const copy = template.cloneNode(true) as HTMLTemplateElement;

    /*
    let current: Node | undefined = copy;
    const children: Node[] = [];

    while (current) {
      current.childNodes.forEach((node) => {
        children.push(node);
      });

      if (current instanceof HTMLElement) {
        for (let i = 0; i < current.attributes.length; i++) {
          const attribute = current.attributes.item(i);
          if (attribute) {
            if (attribute.value) {
              attribute.value = attribute.value.replace(/:id/g, id);
            }
          }
        }
      }

      if (current.textContent) {
        current.textContent = current.textContent.replace(/:title/g, title);
      }

      current = children.shift();
    }
    */

    copy.innerHTML = copy.innerHTML
      .replace(/:id/g, id)
      .replace(/:title/g, this.titleFor(status, title))
      .replace(/:description/g, description);

    const inflated = copy.content.firstElementChild!.cloneNode(true);
    template.parentElement!.append(inflated);

    const focus = document.activeElement || this.element;
    new OneTimeDialog(id, true).open(focus);
  }

  private onFormSuccess(event: Event) {
    if (!('detail' in event) || event.detail === null) {
      return;
    }

    // TODO: Do not support submit-end yet
    if (!Array.isArray(event.detail)) {
      return;
    }

    let [newDocument, , xhr] = event.detail as [
      Document,
      unknown,
      XMLHttpRequest,
    ];

    // Only 200 Ok is supported. When 201 or 30x is given, will probably be handled by @rails/ujs or Turbo(links).
    if (xhr.status !== 200) {
      return;
    }

    // Only HTML is supported. When JS is given, will probably be handled by @rails/ujs.
    if (
      (xhr.getResponseHeader('Content-Type') || '').split(';').shift() !==
      'text/html'
    ) {
      return;
    }

    Turbolinks.clearCache();
    Turbolinks.dispatch('turbolinks:before-cache');

    const newHtmlContent = xhr.responseText;
    const currentSnapshot = Turbolinks.Snapshot.fromHTMLElement(
      document.documentElement as HTMLHtmlElement,
    );
    const newSnapshot = Turbolinks.Snapshot.fromHTMLString(newHtmlContent);

    let renderer = new Turbolinks.SnapshotRenderer(
      currentSnapshot,
      newSnapshot,
      false,
    );

    if (!renderer.shouldRender()) {
      renderer = new Turbolinks.ErrorRenderer(newHtmlContent);
    }

    renderer.delegate = nullDelegate;
    renderer.render(nullCallback);

    // document.title = newDocument.title;
    // document.documentElement.replaceChild(..., document.body);

    Turbolinks.dispatch('turbolinks:load');

    window.scroll(0, 0);
  }

  private freezeForm() {
    const elementsToDisable = this.element.querySelectorAll<
      HTMLButtonElement | HTMLInputElement
    >('button:not([type="button"]), input[type="submit"]');
    elementsToDisable.forEach((button) => {
      if (
        button.hasAttribute('data-disable') ||
        button.hasAttribute('data-disable-with')
      ) {
        // handled by @rails/ujs
        return;
      }

      if (button.hasAttribute('disabled')) {
        // disabled in HTML
        return;
      }

      button.disabled = true;
    });
  }

  private restoreForm() {
    const submitter = this.element.querySelector(
      '[data-form-target="submitted"]',
    );

    if (submitter) {
      submitter.removeAttribute('data-form-target');
      if (submitter.hasAttribute('data-form-restore-value')) {
        submitter.textContent =
          submitter.getAttribute('data-form-restore-value') || '';
        submitter.removeAttribute('data-form-restore-value');
      }
    }

    const elementsToEnable = this.element.querySelectorAll<
      HTMLButtonElement | HTMLInputElement
    >('button:not([type="button"]), input[type="submit"]');
    elementsToEnable.forEach((button) => {
      if (
        button.hasAttribute('data-disable') ||
        button.hasAttribute('data-disable-with')
      ) {
        // handled by @rails/ujs
        return;
      }

      if (button.hasAttribute('disabled')) {
        // disabled in HTML
        return;
      }

      button.disabled = false;
    });
  }

  private extractMessage(message: string | Document): string {
    if (typeof message === 'string') {
      return message;
    }

    return (message.body.textContent?.trim() || '')
      .replace(/\n{4,}/g, '\n')
      .replace(/\n\n+/g, '\n')
      .replace(/ {2,}/g, ' ');
  }

  private descriptionFor(status: number, message: string): string {
    const here = this.hasSourceUrlValue
      ? `<a
        href="${this.sourceUrlValue}"
        target="__blank"
        class="underline hover:no-underline focus:no-underline text-theme-primary-dark-500 hover:text-theme-primary-dark-700 transition">
          confirm here (opens in a new tab)
        </a>`
      : 'confirm';

    const details = message.length < 256 ? message: `${message.substring(0, 255 - 3)}...`

    if (status >= 400 && status < 500) {
      if (status === 404) {
        return `There was a problem with your submission. <br />
          We could not find or retrieve a resource. <br />
          <br />
          If you are certain that the fields are filled in correctly, please notify your Opus consultant so we can resolve this for you. <br />
          <br />
          <strong>Details</strong><br />
          <code class="font-mono text-xs">${details}</code>
        `;
      }

      if (status === 409) {
        return `Someone else made a change that is conflicting with your submission. <br />
        <br />
        Please go back, inspect the changes, and resubmit your request.`;
      }

      if (status === 413) {
        return `The submission is too large. You can submit up to 1000 MB (unless otherwise specified) by first opening the modal with JavaScript enabled instead of directly drag-and-dropping a file or attempting this without JavaScript enabled.<br />
        <br />
        In all other cases, please shrink the file to at most 15 MB and try again.`;
      }

      return `There was a problem with your submission. <br />
        <br />
        Please check if the fields are filled in correctly and try again. <br />
        <br />
        <strong>Details</strong><br />
        <code class="font-mono text-xs">${details}</code>`;
    }

    if (status >= 500 && status < 600) {
      if (status === 500) {
        return `Something broke on our end and we <strong>have been notified</strong> about this issue. <br />
          <br />
          You cannot resolve this from your end. Notify your Opus consultant if your submitted request is critical.`;
      }

      if (status === 502) {
        return `The server was temporarily offline. However, your request <em>may have been processed</em>. <br />
          <br />
          Please ${here} in a few minutes that your request has been processed. You may then try again.`;
      }

      if (status === 504) {
        return `We could not process your request in time, but it was accepted. <br />
          <br />
          Please <strong>do not retry</strong> right away, but in a while (up to 30 minutes) ${here} that your submission has been applied. <br />
          <br />
          You may continue your work without delay. Notify your Opus consultant if this occurs regularly.`;
      }

      return `Something broke on our end (${status}). <br />
        <br />
        You cannot resolve this from your end. Notify your Opus consultant if your submitted request is critical. <br />
        <br />
        <strong>Details</strong><br />
        <code class="font-mono text-xs">${details}</code>`;
    }

    if (status === 0) {
      return `The connection was terminated. <br />
      <br />
      Please check that you have an active internet connection and that your firewall does not block requests to Opus Safety Cloud.
      <br />
      In particular the following domain must be whitelisted as well:
      <code class="font-mono text-xs">kaboom-files.storage.opus-safety.delftsolutions.nl</code>.
      <br />
      If the problem persists and you are confident that you have an active connection and correctly configured firewall, please Notify your Opus consultant.
      `;
    }

    return `Something broke on our end (${status}). <br />
      <br />
      You cannot resolve this from your end. Notify your Opus consultant if your submitted request is critical. <br />
      <br />
      <strong>Details</strong><br />
      <code class="font-mono text-xs">${details}</code>`;
  }

  private titleFor(status: number, title: string): string {
    if (status >= 400 && status < 500) {
      if (status === 404) {
        return 'Resource not found';
      }

      if (status === 409) {
        return 'Conflicting change';
      }

      if (status === 413) {
        return 'Payload too large';
      }

      return 'Submission issue';
    }

    if (status >= 500 && status < 600) {
      if (status === 500) {
        return 'Server issue';
      }

      if (status === 502) {
        return 'Server is offline';
      }

      if (status === 504) {
        return 'Server is slow';
      }

      return 'Unknown server issue';
    }

    if (status === 0) {
      return 'Connection issue';
    }

    return `Unknown issue ${status} (${title})`;
  }
}
