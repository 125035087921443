import { type Context, Controller } from '@hotwired/stimulus';

export default class EditorController extends Controller {
  static values = {
    'interval-field': String,
    'temporary-field': String,
  };

  declare intervalFieldValue: string;
  declare temporaryFieldValue: string;

  constructor(context: Context) {
    super(context);

    this.onChangeTemporary = this.onChangeTemporary.bind(this);
  }

  get intervalField() {
    return this.element.querySelector<HTMLInputElement>(
      `input#${this.intervalFieldValue}`,
    )!;
  }

  get temporaryField() {
    return this.element.querySelector<HTMLInputElement>(
      `input#${this.temporaryFieldValue}`,
    )!;
  }

  connect(): void {
    this.temporaryField.addEventListener('input', this.onChangeTemporary);
  }

  disconnect(): void {
    this.temporaryField.removeEventListener('input', this.onChangeTemporary);
  }

  private onChangeTemporary(this: EditorController, event: Event) {
    this.intervalField.disabled = this.temporaryField.checked;
  }
}
